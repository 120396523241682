.navbar{
    background-color: #f3f3f3;
}

.div-card{
    display: flex;
    justify-content: center;
    margin: auto;
}

.card-header{
    background: black;
    color: white;
}

.card{
    margin: auto;
}

.btn-baixar{
    background: black;
    color: white;
}

.card-alerta{
    background-color: silver;
    border-radius: 5px;
}
