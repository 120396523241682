.form-container{
    height: 80vh;
}

.form-signin{
    width: 330px;
    padding: 15px;
    margin: auto;
}

.form-signin input[type="text"] {
    margin-bottom: 10px;
  }
  
.form-signin input[type="password"] {
    margin-bottom: 10px;
}
